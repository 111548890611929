import React, { Component } from "react";
import { UserContext } from "../../UserContext";
import NavBar from "../general/nav_bar";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ConfirmationModal from "./confirmationModal";
import ErrorPage from "../general/errorPage";
import ColumnComponents from "../lists/ColumnComponent";
import ColumnBlackboard from "../lists/ColumnBlackboard";
import GradesComponents from "../lists/GradesComponent";
import Loader from "../general/loader";
import GradesApi from "../../services/api/getAllGrades";
import ExtractGradesApi from "../../services/api/extractGrades";

class ListGrades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseId: "",
      columnsList: [],
      columnsBbList: [],
      gradesList: [],
      chkColums: {},
      chkColumsBb: {},
      chkGrades: {},
      allData: {},
      GenerlError: {
        error: false,
        errorCode: 0,
        errorMsg: "",
        errorDetails: "",
        redirectUrl: "",
      },
      alert: {
        open: false,
        message: "",
        status: "success",
      },
      loading: true,
      step: 1,
      canContinue: true,
      selectedAllColumns: false,
      selectedAllGrades: false,
      openConfirmation: false,
      hash: '',
      bannerColumnId: {},
      bbColumnId: {}
    };
  };

  componentDidMount = async () => {
    var _self = this;
    const urlParams = new URLSearchParams(window.location.search);
    const hash = urlParams.get("hash");
    try {
      var gradesData = await GradesApi({ hash: hash });
      if (gradesData.data.success) {
        let chk = {};
        let bbColumns = [];
        let chkBb = {};
        // Banner columns
        for (let i = 0; i < gradesData.data.columns.length; i++) {
          chk[gradesData.data.columns[i]["id"]] = false;
        }

        // Blackboard columns
        for (let i = 0; i < gradesData.data.grades.JSON.columns.length; i++) {
          bbColumns.push({
            id: gradesData.data.grades.JSON.columns[i]["column_pk1"],
            name: gradesData.data.grades.JSON.columns[i]["column_name"]
          })
          chkBb[gradesData.data.grades.JSON.columns[i]["column_pk1"]] = false;
        }
        this.setState({
          alert: {
            open: true,
            message: "Datos cargados",
            status: "success",
          },
          chkColums: chk,
          allData: gradesData.data,
          columnsList: gradesData.data.columns,
          courseId: gradesData.data.grades.COURSE_ID,
          canContinue: true,
          chkColumsBb: chkBb,
          columnsBbList: bbColumns,
          hash: hash
        });
      }
    } catch (e) {
      _self.setState({
        alert: {
          open: true,
          message: "Error al cargar los datos",
          status: "error",
        },
        columnsList: [],
        columnsBbList: [],
        allData: {},
        courseId: "",
        canContinue: false,
        hash: hash
      });
    }
    setTimeout(function() {
      _self.onToggleLoader();
    }, 1000);
  };

  closeMessage = () => {
    let x = this.state.alert;
    x.open = false;
    this.setState({ alert: x });
  };

  clickNext = () => {
    let x = this.state.step;
    let valid = false;
    if (x === 1) {
      let bbColumnId = {};
      for (let i = 0; i < this.state.columnsBbList.length; i++) {
        if (this.state.chkColumsBb[this.state.columnsBbList[i]["id"]]){
          valid = true;
          bbColumnId = this.state.columnsBbList[i];
        }
      }
      if( valid ) {
        this.setState({
          step: x + 1,
          bbColumnId: bbColumnId,
          canContinue: true,
        })
      } else {
        this.setState({
          alert: {
            open: true,
            message: "Debe seleccionar al menos 1 fila",
            status: "error",
          },
        });
      }
    } else if (x === 2) {
      let bannerColumnId = {};
      for (let i = 0; i < this.state.columnsList.length; i++) {
        if (this.state.chkColums[this.state.columnsList[i]["id"]]){
          valid = true;
          bannerColumnId = this.state.columnsList[i];
        }
      }
      if( valid ) {
        //Filter columns and grades
        let bbGrades = (this.state.allData && this.state.allData.grades) ? this.state.allData.grades.JSON.grades : [];
        let bannerGrades = (this.state.allData && this.state.allData.externalGrades) ? this.state.allData.externalGrades : [];
        let gradesList = [];
        let chk = {};
        //Adding Blackboard grades to array
        for (let i = 0; i < bbGrades.length; i++) {
          // for (let j = 0; j < this.state.columnsList.length; j++) {
            // if( this.state.chkColums[this.state.columnsList[j]["id"]] ) {
            if( this.state.bbColumnId['id'] == bbGrades[i]['column_pk1'] ) {
              //FOUND COLUMND
              let xGrades = {};
              xGrades['id'] = this.state.bbColumnId['id'];
              xGrades['name'] = this.state.bbColumnId['name'];
              xGrades['rut'] = bbGrades[i]['rut'];
              xGrades['banner_id'] = bannerColumnId["id"];
              xGrades['notaBB'] = bbGrades[i]['grade'];
              xGrades['date'] = bbGrades[i]['created_date'];
              xGrades['studName'] = bbGrades[i]['user_name'];
              xGrades['notaBanner'] = "--";
              xGrades['statusClass'] = "no-status";
              xGrades['statusText'] = "--";
              //Adding Banner grades to array
              for (let y = 0; y < bannerGrades.length; y++) {
                if( this.state.chkColums[bannerColumnId["id"]] && bannerColumnId["id"] == bannerGrades[y]['columnId']
                && bbGrades[i]['rut'].toUpperCase() == bannerGrades[y]['studentId'].toUpperCase() ) {
                  // if( this.state.columnsList[j]['name'] == bannerGrades[y]['columnName']
                  // && this.state.chkColums[this.state.columnsList[j]["id"]]
                  // && bbGrades[i]['rut'] == bannerGrades[y]['studentId'] ) {
                  xGrades['notaBanner'] = bannerGrades[y]['grade'];
                  xGrades['studName'] = bannerGrades[y]['studentName'];
                }
              }
              chk[bannerColumnId["id"] + "_" + x['rut']] = false;
              gradesList.push(xGrades);
            }
          // }
        }
        this.setState({
          step: x + 1,
          chkGrades: chk,
          gradesList: gradesList,
          canContinue: true,
          bannerColumnId: bannerColumnId
        });
      } else {
        this.setState({
          alert: {
            open: true,
            message: "Debe seleccionar al menos 1 fila",
            status: "error",
          },
        });
      }
    } else {
      let objGradesChk = Object.keys(this.state.chkGrades);
      for (let i = 0; i < objGradesChk.length; i++) {
        if (this.state.chkGrades[objGradesChk[i]]) valid = true;
      }
      if( valid ) {
        // Open modal to show warning
        this.setState({
          openConfirmation: true
        })
      } else {
        this.setState({
          alert: {
            open: true,
            message: "Debe seleccionar al menos 1 fila",
            status: "error",
          },
        });
      }
    }
  };
  
  clickBack = () => {    
    let x = this.state.step;
    this.setState({
      step: x - 1,
      canContinue: true,
    });
  };

  clickDone = () => {
    this.setState({
      step: 1,
      canContinue: true,
    });
  };

  onToggleLoader = () => {
    let x = this.state.loading;
    this.setState({ loading: !x });
  };

  onClickColumn = (e, y) => {
    if( y === 'columns' ){
      let x = this.state.chkColums;
      let objKC = Object.keys(x);
      for( let i = 0; i < objKC.length; i += 1 ){
        x[objKC[i]] = false;
      }
      x[e] = true;
      this.setState({
        chkColums: x,
      });
    } else if( y === 'bb' ){
      let x = this.state.chkColumsBb;
      let objKC = Object.keys(x);
      for( let i = 0; i < objKC.length; i += 1 ){
        x[objKC[i]] = false;
      }
      x[e] = true;
      this.setState({
        chkColumsBb: x,
      });
    } else if( y === 'grades' ){
      let x = this.state.chkGrades;
      x[e] = !x[e];
      this.setState({
        chkGrades: x,
      });
    }
  };

  checkAll = (e) => {
    if( e === 'columns' ){
      var ele = document.getElementsByName("chk");
      let x = !this.state.selectedAllColumns;
      for (let i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") ele[i].checked = x;
      }
      let chk = {};
      for (let i = 0; i < this.state.columnsList.length; i++) {
        chk[this.state.columnsList[i]["id"]] = x;
      }
      this.setState({
        selectedAllColumns: x,
        chkColums: chk,
      });
    } else if( e === 'grades' ) {
      var ele = document.getElementsByName("grads");
      let x = !this.state.selectedAllGrades;
      for (let i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") ele[i].checked = x;
      }
      let chk = {};
      for (let i = 0; i < this.state.gradesList.length; i++) {
        chk[this.state.gradesList[i]["id"] + "_" + this.state.gradesList[i]["rut"]] = x;
      }
      this.setState({
        selectedAllGrades: x,
        chkGrades: chk,
      });
    }
  };

  onCloseModal = () => {
    this.setState({
      openConfirmation: false
    });
  };

  onConfirmModal = async () => {
    var _self = this;
    this.setState({
      loading: true,
      openConfirmation: false
    });
    // Extract grades to Backend
    let chG = this.state.chkGrades;
    let gradesList = this.state.gradesList;
    let hash = this.state.hash;
    let gradesExtract = [];
    for( let i = 0; i < gradesList.length; i++ ) {
      if( chG[gradesList[i]['id'] + "_" + gradesList[i]['rut']] ) {
        gradesExtract.push(gradesList[i]);
      }
    }
    try{
      let gradesResponse = await ExtractGradesApi({
        hash: hash,
        grades: gradesExtract
      });
      if( gradesResponse.data && gradesResponse.data.success ) {
        setTimeout(function() {
          let x = _self.state.chkColums;
          let y = _self.state.chkGrades;
          let z = _self.state.chkColumsBb;
          let xObj = Object.keys(x);
          for( let i = 0; i < xObj.length; i++ ) {
            x[xObj[i]] = false;
          }
          let yObj = Object.keys(y);
          for( let i = 0; i < yObj.length; i++ ) {
            y[yObj[i]] = false;
          }
          let zObj = Object.keys(z);
          for( let i = 0; i < zObj.length; i++ ) {
            z[zObj[i]] = false;
          }
          let allGrades = _self.state.gradesList;
          let bannerGrades = (_self.state.allData && _self.state.allData.externalGrades) ? _self.state.allData.externalGrades : [];
          for( let j = 0; j < allGrades.length; j += 1 ) {
            for( let i = 0; i < gradesResponse.data.data.length; i += 1 ) {
              if( allGrades[j]['id'] == gradesResponse.data.data[i]['column_pk1'] 
              && allGrades[j]['rut'].toUpperCase() == gradesResponse.data.data[i]['rut'].toUpperCase() ) {
                // console.log('FOUND');
                // console.log(allGrades[j])
                if( gradesResponse.data.data[i]['extracted'] ) {
                  allGrades[j]['statusClass'] = "success-status";
                  allGrades[j]['statusText'] = "Nota Traspasada";
                  allGrades[j]['notaBanner'] = allGrades[j]['notaBB'];
                  for (let y = 0; y < bannerGrades.length; y++) {
                    if( allGrades[i]['banner_id'] == bannerGrades[y]['columnId']
                    && allGrades[j]['rut'].toUpperCase() == bannerGrades[y]['studentId'].toUpperCase() ) {
                      bannerGrades[y]['grade'] = allGrades[j]['notaBB'];
                    }
                  }
                } else {
                  allGrades[j]['statusClass'] = "error-status";
                  allGrades[j]['statusText'] = gradesResponse.data.data[i]['json'];
                }
              }
            }
          }
          let adllObj = _self.state.allData;
          if( bannerGrades && bannerGrades.length ) {
            adllObj.externalGrades = bannerGrades;
          }
          _self.setState({
            loading: false,
            selectedAllColumns: false,
            selectedAllGrades: false,
            chkColums: x,
            allData: adllObj,
            step: 4,
            chkGrades: y,
            chkColumsBb: z,
            gradesList: allGrades,
            alert: {
              open: true,
              message: "Traspaso Procesado",
              status: "success",
            },
          });
        }, 1000);
      } else {
        _self.setState({
          loading: false,
          alert: {
            open: true,
            message: "Error en Traspaso (" + hash + ")",
            status: "error",
          },
        });
      }
    } catch(e) {
      _self.setState({
        loading: false,
        alert: {
          open: true,
          message: "Error en Traspaso (" + hash + ")",
          status: "error",
        },
      });
    }
  };

  render() {
    let tblRender = null;
    let continueBtn = (
      <button
        type="button"
        style={{ float: "right", marginRight: "4%" }}
        className="btn btn-primary btn-purple"
        onClick={() => this.clickNext()}
      >
        Continuar
      </button>
    );
    if ((this.state.step === 1 || this.state.step === 2) && this.state.canContinue == false) {
      continueBtn = (
        <button
          type="button"
          style={{ float: "right", marginRight: "4%" }}
          disabled
          className="btn btn-primary btn-purple"
          onClick={() => this.clickNext()}
        >
          Continuar
        </button>
      );
    }

    let extractBtn = (
      <button
        type="button"
        style={{ float: "right", marginRight: "4%" }}
        className="btn btn-primary btn-purple"
        onClick={() => this.clickNext()}
      >
        Extraer
      </button>
    );

    let endBtn = (
      <button
        type="button"
        style={{ float: "right", marginRight: "4%" }}
        className="btn btn-primary btn-purple"
        onClick={() => this.clickDone()}
      >
        Finalizar
      </button>
    );
    if (this.state.step === 1) {
      tblRender = (
        <div>
          <ColumnBlackboard
            dataList={this.state.columnsBbList}
            totalRows={this.state.columnsBbList.length}
            onToggleLoader={() => this.onToggleLoader()}
            courseId={this.state.courseId}
            onClickColumn={(e) => this.onClickColumn(e, 'bb')}
            chks={this.state.chkColumsBb}
          />
          {continueBtn}
        </div>
      );
    } else if (this.state.step === 2) {
      tblRender = (
        <div>
          <ColumnComponents
            dataList={this.state.columnsList}
            totalRows={this.state.columnsList.length}
            onToggleLoader={() => this.onToggleLoader()}
            courseId={this.state.courseId}
            onClickColumn={(e) => this.onClickColumn(e, 'columns')}
            chks={this.state.chkColums}
          />
          <button
            type="button"
            style={{ marginLeft: "4%" }}
            className="btn btn-primary btn-purple"
            onClick={() => this.clickBack()}
          >
            Atrás
          </button>
          {continueBtn}
        </div>
      );
    } else if (this.state.step === 3) {
      tblRender = (
        <div>
          <ConfirmationModal 
            open={this.state.openConfirmation}
            onCloseModal={() => this.onCloseModal()}
            onConfirm={() => this.onConfirmModal()}
          />
          <GradesComponents
            dataList={this.state.gradesList}
            totalRows={this.state.gradesList.length}
            onToggleLoader={() => this.onToggleLoader()}
            courseId={this.state.courseId}
            onClickColumn={(e) => this.onClickColumn(e, 'grades')}
            checkAll={() => this.checkAll('grades')}
            chks={this.state.chkGrades}
          />
          <button
            type="button"
            style={{ marginLeft: "4%" }}
            className="btn btn-primary btn-purple"
            onClick={() => this.clickBack()}
          >
            Atrás
          </button>
          {extractBtn}
        </div>
      );
    } else if (this.state.step === 4) {
      tblRender = (
        <div>
          <GradesComponents
            dataList={this.state.gradesList}
            totalRows={this.state.gradesList.length}
            onToggleLoader={() => this.onToggleLoader()}
            courseId={this.state.courseId}
            onClickColumn={(e) => this.onClickColumn(e, 'grades')}
            checkAll={() => this.checkAll('grades')}
            chks={this.state.chkGrades}
          />
          {endBtn}
        </div>
      );
    }

    return (
      <div>
        <NavBar />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={5000}
          open={this.state.alert.open}
          onClose={this.closeMessage}
        >
          <MuiAlert
            onClose={this.closeMessage}
            severity={this.state.alert.status}
            variant="filled"
          >
            {this.state.alert.message}
          </MuiAlert>
        </Snackbar>
        <Loader loading={this.state.loading} />
        {this.state.GenerlError.error === false ? (
          tblRender
        ) : (
          <div>
            <ErrorPage
              errorCode={this.state.GenerlError.errorCode}
              errorMsg={this.state.GenerlError.errorMsg}
              errorDetails={this.state.GenerlError.errorDetails}
              redirectUrl={this.state.GenerlError.redirectUrl}
            />
          </div>
        )}
      </div>
    );
  }
}

ListGrades.contextType = UserContext;
export default ListGrades;
