import React, { Component } from 'react';
import { UserContext } from '../../UserContext';
// import logoutApi from '../../services/api/logout';

//import { useTranslation, Trans } from 'react-i18next';


class NavBar extends Component {
   // constructor(props) {
      //  super(props);
      //  this.myRef = React.createRef();
    //}
    state = {
        lang: this.context.lang,
        location: "",
        count: 1,
        routesNav: [{}],
        token: this.context.token,
    }
    async componentDidMount() {
        this.setState({ routesNav: [],
                        lang: 'es',
                        location: window.location.pathname});
        //let ul = this.myRef.myRef;
        //const { t } = Translation(this.state.lang);
    }
    removeActive = (event) => {
        for(let y=0; y<event.childNodes.length; y++){
            event.childNodes[y].classList.remove("active")
        }
    }
    setActive = (event) =>{
        console.log("get in here")
        let parentLi = event.target.parentElement
        let parentUl = parentUl.parentElement
        this.removeActive(parentUl)
        parentLi.classList.add("active")
    }

    logout = async (e) => {
        const tokenAuth = this.state.token;
        // await logoutApi({
        //     token: tokenAuth
        // });
        window.close();
    }

    render() {

        var navList = (
            this.state.routesNav.map((x, i) =>
                <li className={this.state.location === '/lti/' + x.value  ? "nav-item pr-4 active" : "nav-item pr-4"} >
                    <a className={"nav-link link-" + x.value + ""} href={"/lti/" + x.value} >{x.name}</a>
                </li>
            )
        )
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-dark topbar pr-4 pl-4">
                    <a className="navbar-brand logo" >Extraer Notas</a>
                     {/*<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#listNavbar" aria-controls="listNavbar" aria-expanded="false" aria-label="Toggle navigation"> 
                         <span className="navbar-toggler-icon"></span>
                    </button> 
                     <div className="collapse navbar-collapse justify-content-end mr-auto" id="listNavbar">
                        <ul className="navbar-nav" style={{marginRight: '4%'}} ref={this.myRef}>
                            {navList}
                            <li className="nav-item">
                                <a className="nav-link link-logout" onClick={() => this.logout()}>Salir</a>
                            </li>
                        </ul>
                    </div> */}
                </nav>
            </div>
        );
    }

}

NavBar.contextType = UserContext;

export default NavBar;