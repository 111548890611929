import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

import translationEN from './translations/en.json';
import translationES from './translations/es.json';
import translationPT from './translations/pt.json';


const resources = {
  en: {
    translations: translationEN
  },
  es: {
    translations: translationES
  },
  pt: {
    translations: translationPT
  }
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    //lng: 'es',
    resources: resources,
    fallbackLng: 'es',
    debug: false,
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },
    react: {
      wait: true
    }
  });

export default i18n;