import React from 'react'
import Paging from './paging';

const tableComponent = (props) => {
    let x = props.tableHeaders.length;
    let bdyData = (<tr><td style={{textAlign: "center"}} colSpan={x}>No hay datos.</td></tr>);
    if( props.dataList )
        bdyData = props.dataList;
    return (
        <div>
            <div className="table-responsive ">
                <table className="table table-borderless table-hover">
                    <thead>
                        <tr >
                            {props.tableHeaders}
                        </tr>
                    </thead>
                    {bdyData}
                </table>
            </div>

            {/* <div className="d-flex mt-4 container-pagination">
                <span className="mr-auto">Mostrando {props.dataListLength} de {props.totalRows} </span>
                <Paging
                    pages={Math.ceil(props.totalRows / 20)}
                    refreshpage={(event) => props.pagingRefreshDataList(event)}
                />
            </div> */}
        </div>
    )
}
export default tableComponent