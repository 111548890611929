import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { UserContext } from "./UserContext";
import GradesList from "./components/grades/list";
import ErrorPage from "./components/general/errorPage";
import GradesApi from "./services/api/validateHash";
import "./App.css";
import "./theme/css/custom.css";
import "./theme/css/bootstrap-reboot.min.css";
import "./theme/css/bootstrap.min.css";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
//import i18next from "i18next";

class App extends Component {
  state = {
    hash: '',
    GenerlError: {
      error: false,
      errorCode: 0,
      errorMsg: "",
      errorDetails: "",
      redirectUrl: "",
    },
  };
  async componentDidMount() {
    var _self = this;
    const urlParams = new URLSearchParams(window.location.search);
    const hash = urlParams.get("hash");

    if (!hash || hash === "") {
      this.setState({
        GenerlError: {
          error: true,
          errorCode: 404,
          errorMsg: "Not Found - Error",
          errorDetails: "Invalid Hash",
        },
      });
    } else {
      try {
        let response = await GradesApi({ hash });
        if (response.data.success) {
          this.setState({
            hash: hash
          });
        } else {
          this.setState({
            GenerlError: {
              error: true,
              errorCode: 404,
              errorMsg: "Not Found - Error",
              errorDetails: response.data.data,
            },
          });
        }
      } catch (e) {
        _self.setState({
          GenerlError: {
            error: true,
            errorCode: 404,
            errorMsg: "Not Found - Error",
            errorDetails: e,
          },
        });
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.GenerlError.error === false ? (
            <UserContext.Provider value={this.state.hash}>
              <I18nextProvider i18n={i18n}>
                <BrowserRouter>
                  <Switch>
                    <Route exact path="/" component={GradesList} />
                    <Route
                      exact
                      path="*"
                      component={
                        <ErrorPage
                          errorCode={"404"}
                          errorMsg={"Pagina no encontrada"}
                          errorDetails={
                            "La página que intentas solicitar no se encuentra disponible."
                          }
                          redirectUrl={"/"}
                        />
                      }
                    />
                  </Switch>
                </BrowserRouter>
              </I18nextProvider>
            </UserContext.Provider>
          ) : (
          <div>
            <ErrorPage
              errorCode={this.state.GenerlError.errorCode}
              errorMsg={this.state.GenerlError.errorMsg}
              errorDetails={this.state.GenerlError.errorDetails}
              redirectUrl={this.state.GenerlError.redirectUrl}
            />
          </div>
        )}
      </div>
    );
  }
}
export default App;
