import React, { Component } from "react";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";

class LoaderPage extends Component {
  render() {
    let x = "noshow";
    if (this.props.loading) x = "show";
    let h = document.getElementById("root").offsetHeight;

    return (
      <div
        className={x}
        style={{ position: "absolute", width: "100%", height: h + "px" }}
      >
        <div
          className="container"
          style={{ width: "100% !important", margin: "0px", padding: "0px" }}
        >
          <div
            className=""
            style={{ height: "100%", width: "100%", position: "absolute" }}
          >
            <LoadingOverlay
              style={{ height: "100%", width: "100%", position: "absolute" }}
            >
              <Loader loading={this.props.loading} />
            </LoadingOverlay>
          </div>
        </div>
      </div>
    );
  }
}

export default LoaderPage;
