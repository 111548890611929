import React from 'react'
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

const errorPage = (props) => (
    <div>
        <div className="container">
            <div className="content-container p-5 mt-5 mx-auto  text-center" style={{ width: '75%' }}>
                <h1>Error {props.errorCode}</h1>
                <h2 className="mr-auto">{props.errorMsg}</h2>
                <p>{props.errorDetails}</p>
                <Tooltip title="Regresar al curso" aria-label="add">
                    <button type="button" className="btn btn-primary btn-purple"><Icon style={{ fontSize: 30 }}>arrow_back_circle</Icon></button>
                </Tooltip>	
            </div>
        </div>
    </div>   
)

export default errorPage;
