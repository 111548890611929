import server from "../server";

/**
 * This is a method that get a grades/info from hash
 * @param {string} hash - The HASH ID of the grades.
 * @returns {promise} Returns a promise with what the backend returns to us. In this case a info of grades.
 */

const getValidHash = ({ hash }) => {
  return server
    .get(`/grades/validate?hash=${hash}`)
    .then(function(data) {
      return Promise.resolve(data);
    })
    .catch(function(error) {
      return Promise.reject(error);
    });
};

export default getValidHash;
