import React from "react";
import Modal from "react-responsive-modal";
import "../../theme/css/custom.css";

const ConfirmationModal = (props) => {
  return (
    <Modal
      id="myModal"
      open={props.open}
      className="modal fade"
      onClose={props.onCloseModal}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Confirmar Traspaso</h5>
          </div>
          <div className="modal-body">
            <p>¿Está seguro que quiere extraer las calificaciones? (Las calificaciones de Banner pueden ser sobre-escritas)</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary btn-purple"
              data-dismiss="modal"
              onClick={props.onCloseModal}
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn btn-primary btn-orange"
              onClick={props.onConfirm}
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
