import React, { Component } from "react";
import TableComponent from "../general/tableComponent";

class GradesComponent extends Component {
  render() {
    let dataList = null;
    let tableHeaders = [];
    tableHeaders.push(<th><input type="checkbox" id="selectAll" name="selectAll" value="selectAll" onClick={() => this.props.checkAll()} /></th>);
    tableHeaders.push(<th>ID</th>);
    tableHeaders.push(<th>NOMBRE COLUMNA</th>);
    tableHeaders.push(<th>RUT ESTUDIANTE</th>);
    tableHeaders.push(<th>NOMBRE ESTUDIANTE</th>);
    tableHeaders.push(<th>NOTA BB</th>);
    tableHeaders.push(<th>NOTA BANNER</th>);
    tableHeaders.push(<th>FECHA</th>);
    tableHeaders.push(<th>ESTADO</th>);

    if (this.props.dataList != null && this.props.dataList.length) {
      dataList = (
        <tbody>
          {this.props.dataList.map((x) => (
            <tr id={x.id}>
              <td><input type="checkbox" id={"grads_" + x.id + "_" + x.rut} name="grads" value={"grads_" + x.id + "_" + x.rut} checked={this.props.chks[x.id + "_" + x.rut] == true ? "checked" : ""} onClick={() => this.props.onClickColumn(x.id + "_" + x.rut)} /></td>
              <td>{x.id}</td>
              <td>{x.name}</td>
              <td>{x.rut}</td>
              <td>{x.studName}</td>
              <td>{x.notaBB}</td>
              <td>{x.notaBanner}</td>
              <td>{x.date}</td>
              <td className={x.statusClass}>{x.statusText}</td>
            </tr>
          ))}
        </tbody>
      );
    }

    return (
      <div>
        <div className="content-container p-4 mb-4">
          <div className="d-flex main-header">
            <h2 className="mr-auto">Notas</h2>
          </div>
          <div className="d-flex mb-3 main-header">
            <h4 className="mr-auto">Curso: {this.props.courseId}</h4>
          </div>
          <TableComponent
            tableHeaders={tableHeaders}
            dataList={dataList}
            dataListLength={this.props.dataList.length}
            totalRows={this.props.totalRows}
            pagingRefreshDataList={(event) => this.props.refreshDataList(event)}
          />
        </div>
      </div>
    );
  }
}

export default GradesComponent;
