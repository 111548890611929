import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_API_VERSION,
    crossDomain: true,
    withCredentials: true,
});

server.interceptors.request.use(request => {
    return request;
}, error => {
    if(axios.isCancel(error)){
      //  console.log(error.response)
        return Promise.reject({
            message: "request canceled.",
            messageCode: "ClientClosedRequest",
            isRequestCanceled: true,
            code: 499 // means client has closed the request before the server send response
        });
    }
});

server.interceptors.response.use(response =>{
//    console.log(response)
    return response
}, function (error) {
 //   console.log(error)
    return error
});
export default server;