import React, { Component } from "react";
import TableComponent from "../general/tableComponent";

class ColumnBlackboard extends Component {

  render() {
    let dataList = null;
    let tableHeaders = [];
    tableHeaders.push(<th></th>);
    tableHeaders.push(<th>ID</th>);
    tableHeaders.push(<th>NOMBRE COLUMNA</th>);

    if (this.props.dataList != null && this.props.dataList.length) {
      dataList = (
        <tbody>
          {this.props.dataList.map((x) => (
            <tr id={x.pk1} >
              <td><input type="checkbox" id={"colbb_" + x.id} name="colbb" value={"colbb_" + x.id} checked={this.props.chks[x.id] == true ? "checked" : ""} onClick={() => this.props.onClickColumn(x.id)} /></td>
              <td>{x.id}</td>
              <td>{x.name}</td>
            </tr>
          ))}
        </tbody>
      );
    }

    return (
      <div>
        <div className="content-container p-4 mb-4">
          <div className="d-flex main-header">
            <h2 className="mr-auto">Columnas / Notas Blackboard</h2>
          </div>
          <div className="d-flex mb-3 main-header">
            <h4 className="mr-auto">Curso: {this.props.courseId}</h4>
          </div>
          <TableComponent
            tableHeaders={tableHeaders}
            dataList={dataList}
            dataListLength={this.props.dataList.length}
            totalRows={this.props.totalRows}
            pagingRefreshDataList={(event) => this.props.refreshDataList(event)}
          />
        </div>
      </div>
    );
  }
}

export default ColumnBlackboard;
